import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { IconContext } from 'react-icons';
import { MdLayers, MdVerifiedUser, MdCast, MdFormatPaint } from 'react-icons/md';
import { Tabs } from 'antd';
import styled from 'styled-components';
import '../styles/eifs.css';

// components
import Layout from '../components/layout';
import Seo from '../components/seo';
import MiniHeader from '../components/mini-header';
import CardProduct from '../components/card-product';

// img
import headerBackground from '../images/eifs/header-eifs.jpg';

// styled-component
const Eifs = styled.section`
  padding: 2em 0;
`;

const DContainer = styled.div`
  max-width: 90%;
  width: 980px;
  margin: 0 auto;
`;

const EifsP = styled.p`
  color: #999999;
  font-size: 1rem;
  font-weight: 100;
  line-height: initial;
`;

const EifsIntro = styled.article`
  line-height: 4em;
`;

const EifsTitle = styled.div`
  font-size: 2em;
  font-weight: 100;
`;

const EifsSubtitle = styled.span`
  font-size: 1.3em;
  font-weight: 500;
`;

const EifsTitleText = styled.div`
  text-align: center;
  text-transform: uppercase;
  color: #999999;
  font-size: 18px;
  line-height: 1;
`;

const EifsTitleSeparator = styled.div`
  height: 1px;
  width: 100%;
  background-color: #999999;
`;

const EifsDivider = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.7fr 1fr;
  align-items: center;
  padding: 15px 0;
`;

const EifsCards = styled.div`
  padding: 1em 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex: 1;
  flex-wrap: wrap;
`;

const EifsArticle = styled.article`
  padding: 1.5em 0;
`;
const EifsArticleGray = styled.article`
  padding: 1.5em 0;
  background-color: #f0f0f0;
`;

const EifsFeatures = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 0.75fr 1fr 0.49fr;
  gap: 0 1.2em;
`;

const EifsFeaturesImage = styled.div`
  padding: 1em 1em;
`;

const FeatureTitle = styled.div`
  font-size: 1em;
  color: #999999;
  font-weight: 600;
  border-bottom: 1px solid #999999;
  padding-bottom: 0.4em;
  text-transform: uppercase;
`;

const FeatureItem = styled.div`
  display: flex;
  padding-top: 1rem;
  padding-left: 0.2rem;
  align-items: center;
`;

const FeatureItemSpan = styled.span`
  padding-left: 0.5rem;
  font-size: 0.9rem;
  color: #333333;
`;

const FeatureButtons = styled.div`
  display: flex;
  flex-direction: column;
`;

const FeatureButton = styled(Link)`
  margin-top: 0.5rem;
  padding: 0.1rem 0;
  width: 100%;
  text-align: center;
  border: 1px solid #999999;
  background: #999999;
  color: #fff;
  font-size: 0.8rem;

  &:hover {
    background: rgb(255, 215, 0);
    color: #000;
    text-decoration: underline;
    text-decoration-color: #fff;
  }
`;

const EifsTabsTitle = styled.div`
  padding-top: 1rem;
  border-bottom: 1px solid rgb(255, 215, 0);
`;

const EifsTabsTitleH1 = styled.h1`
  font-weight: 100;
  margin: 0 0 0.5rem 0;
`;

const EifsTabsDescription = styled.div`
  display: grid;
  grid-template-columns: 0.75fr 1fr 0.49fr;
  grid-template-rows: 1fr;
  gap: 0 1.2em;
`;

const { TabPane } = Tabs;

const EIFS = React.memo(() => {
  const data = useStaticQuery(graphql`
    {
      mineral: file(relativePath: { eq: "eifs/SB_StoTherm-ci_Mineral.png" }) {
        childImageSharp {
          gatsbyImageData(width: 300, quality: 100, layout: CONSTRAINED)
        }
      }
    }
  `);
  return (
    <Layout>
      <Seo title="EIFS" />
      <MiniHeader text="EIFS" background={headerBackground} />
      <IconContext.Provider value={{ className: 'eifs-icons' }}>
        <Eifs>
          <DContainer>
            <div>
              <EifsIntro>
                <EifsTitle>
                  <span>StoTherm® ci Wall Systems</span>
                </EifsTitle>
                <EifsSubtitle>
                  Integrated Wall Systems that deliver the best in performance, sustainability and
                  design flexibility.
                </EifsSubtitle>
                <EifsP>
                  StoTherm® ci Systems integrate the following key elements – continuous insulation
                  (ci), air/moisture barrier, drainage and a variety of textured finish options to
                  create a superior, sustainable wall cladding. This high-performing, eye-catching
                  system saves energy and stays attractive for years. StoTherm® ci improves indoor
                  comfort and air quality while maintaining maximum curb appeal and lowering overall
                  life-cycle costs.
                </EifsP>
              </EifsIntro>
              <article>
                <EifsDivider>
                  <EifsTitleSeparator />
                  <EifsTitleText>OUR FEATURED SYSTEMS</EifsTitleText>
                  <EifsTitleSeparator />
                </EifsDivider>
                <EifsCards className="eifs-cards">
                  <CardProduct
                    background="eifs/SB_StoTherm-ci_Mineral.png"
                    link="/"
                    text="STOTHERM® CI MINERAL"
                  />
                  <CardProduct
                    background="eifs/SB_StoTherm-ci_Mineral.png"
                    link="/"
                    text="STOTHERM® CI MINERAL"
                  />
                  <CardProduct
                    background="eifs/SB_StoTherm-ci_Mineral.png"
                    link="/"
                    text="STOTHERM® CI MINERAL"
                  />
                </EifsCards>
              </article>
            </div>
          </DContainer>
          <EifsArticleGray>
            <DContainer>
              <EifsIntro>
                <EifsTitle>
                  <span>StoTherm® ci Mineral</span>
                </EifsTitle>
                <EifsSubtitle>
                  Durable, high performance, continuous insulation wall system incorporating Mineral
                  Wool.
                </EifsSubtitle>
                <EifsP>
                  The StoTherm® ci Mineral System is a decorative and protective exterior wall
                  system that combines superior air and weather tightness with excellent thermal
                  performance and fire resistance. It incorporates noncombustible continuous
                  exterior insulation and a continuous air and moisture barrier with Sto’s high
                  performance finishes to produce an advanced high performance wall cladding
                  assembly.
                </EifsP>
              </EifsIntro>
              <EifsFeatures>
                <EifsFeaturesImage>
                  <GatsbyImage
                    image={data.mineral.childImageSharp.gatsbyImageData}
                    alt="Sto_Mineral"
                  />
                </EifsFeaturesImage>
                <div>
                  <FeatureTitle>
                    <span>Features</span>
                  </FeatureTitle>
                  <FeatureItem>
                    <MdLayers />
                    <FeatureItemSpan>
                      Fully integrated high density mineral wool core
                    </FeatureItemSpan>
                  </FeatureItem>
                  <FeatureItem>
                    <MdVerifiedUser />
                    <FeatureItemSpan>Specially designed dowel attachment system</FeatureItemSpan>
                  </FeatureItem>
                  <FeatureItem>
                    <MdCast />
                    <FeatureItemSpan>
                      Fully integrated seamless air and moisture barrier
                    </FeatureItemSpan>
                  </FeatureItem>
                  <FeatureItem>
                    <MdFormatPaint />
                    <FeatureItemSpan>
                      Virtually unlimited finish color selection in multiple textures
                    </FeatureItemSpan>
                  </FeatureItem>
                </div>
                <div>
                  <FeatureTitle>
                    <span>Información & Descargas</span>
                  </FeatureTitle>
                  <FeatureButtons>
                    <FeatureButton to="/">Brochure</FeatureButton>
                    <FeatureButton to="/">Design Guide</FeatureButton>
                    <FeatureButton to="/">Install Guide</FeatureButton>
                    <FeatureButton to="/">Specification</FeatureButton>
                    <FeatureButton to="/">System Bulletin</FeatureButton>
                    <FeatureButton to="/">Sto Thermo Dowel Product Bulletin</FeatureButton>
                  </FeatureButtons>
                </div>
              </EifsFeatures>
            </DContainer>
          </EifsArticleGray>
          <EifsArticle>
            <DContainer>
              <EifsIntro>
                <EifsTitle>
                  <span>StoTherm® ci Mineral</span>
                </EifsTitle>
                <EifsSubtitle>
                  Durable, high performance, continuous insulation wall system incorporating Mineral
                  Wool.
                </EifsSubtitle>
                <EifsP>
                  The StoTherm® ci Mineral System is a decorative and protective exterior wall
                  system that combines superior air and weather tightness with excellent thermal
                  performance and fire resistance. It incorporates noncombustible continuous
                  exterior insulation and a continuous air and moisture barrier with Sto’s high
                  performance finishes to produce an advanced high performance wall cladding
                  assembly.
                </EifsP>
              </EifsIntro>
              <EifsFeatures>
                <EifsFeaturesImage>
                  <GatsbyImage
                    image={data.mineral.childImageSharp.gatsbyImageData}
                    alt="Sto_Mineral"
                  />
                </EifsFeaturesImage>
                <div>
                  <FeatureTitle>
                    <span>Features</span>
                  </FeatureTitle>
                  <FeatureItem>
                    <MdLayers />
                    <FeatureItemSpan>
                      Fully integrated high density mineral wool core
                    </FeatureItemSpan>
                  </FeatureItem>
                  <FeatureItem>
                    <MdVerifiedUser />
                    <FeatureItemSpan>Specially designed dowel attachment system</FeatureItemSpan>
                  </FeatureItem>
                  <FeatureItem>
                    <MdCast />
                    <FeatureItemSpan>
                      Fully integrated seamless air and moisture barrier
                    </FeatureItemSpan>
                  </FeatureItem>
                  <FeatureItem>
                    <MdFormatPaint />
                    <FeatureItemSpan>
                      Virtually unlimited finish color selection in multiple textures
                    </FeatureItemSpan>
                  </FeatureItem>
                </div>
                <div>
                  <FeatureTitle>
                    <span>Información & Descargas</span>
                  </FeatureTitle>
                  <FeatureButtons>
                    <FeatureButton to="/">Brochure</FeatureButton>
                  </FeatureButtons>
                </div>
              </EifsFeatures>
            </DContainer>
          </EifsArticle>
          <EifsArticleGray>
            <DContainer>
              <EifsIntro>
                <EifsTitle>
                  <span>StoTherm® ci Mineral</span>
                </EifsTitle>
                <EifsSubtitle>
                  Durable, high performance, continuous insulation wall system incorporating Mineral
                  Wool.
                </EifsSubtitle>
                <EifsP>
                  The StoTherm® ci Mineral System is a decorative and protective exterior wall
                  system that combines superior air and weather tightness with excellent thermal
                  performance and fire resistance. It incorporates noncombustible continuous
                  exterior insulation and a continuous air and moisture barrier with Sto’s high
                  performance finishes to produce an advanced high performance wall cladding
                  assembly.
                </EifsP>
              </EifsIntro>
              <EifsFeatures>
                <EifsFeaturesImage>
                  <GatsbyImage
                    image={data.mineral.childImageSharp.gatsbyImageData}
                    alt="Sto_Mineral"
                  />
                </EifsFeaturesImage>
                <div>
                  <FeatureTitle>
                    <span>Features</span>
                  </FeatureTitle>
                  <FeatureItem>
                    <MdLayers />
                    <FeatureItemSpan>
                      Fully integrated high density mineral wool core
                    </FeatureItemSpan>
                  </FeatureItem>
                  <FeatureItem>
                    <MdVerifiedUser />
                    <FeatureItemSpan>Specially designed dowel attachment system</FeatureItemSpan>
                  </FeatureItem>
                  <FeatureItem>
                    <MdCast />
                    <FeatureItemSpan>
                      Fully integrated seamless air and moisture barrier
                    </FeatureItemSpan>
                  </FeatureItem>
                  <FeatureItem>
                    <MdFormatPaint />
                    <FeatureItemSpan>
                      Virtually unlimited finish color selection in multiple textures
                    </FeatureItemSpan>
                  </FeatureItem>
                </div>
                <div>
                  <FeatureTitle>
                    <span>Información & Descargas</span>
                  </FeatureTitle>
                  <FeatureButtons>
                    <FeatureButton to="/">Brochure</FeatureButton>
                  </FeatureButtons>
                </div>
              </EifsFeatures>
            </DContainer>
          </EifsArticleGray>
          <EifsArticle>
            <DContainer>
              <EifsIntro>
                <EifsTitle>
                  <span>StoTherm® ci Accessories & Sto Turbostick™ Adhesive</span>
                </EifsTitle>
                <Tabs defaultActiveKey="1" type="card" size="mediun">
                  <TabPane tab="Sto TurboStick™" key="1">
                    <div>
                      <EifsTabsTitle>
                        <EifsTabsTitleH1>Sto TurboStick™</EifsTabsTitleH1>
                      </EifsTabsTitle>
                      <EifsTabsDescription>
                        <div></div>
                        <div></div>
                        <div></div>
                      </EifsTabsDescription>
                    </div>
                  </TabPane>
                  <TabPane tab="Corner Bead" key="2"></TabPane>
                  <TabPane tab="Drip Edge Profile" key="3"></TabPane>
                </Tabs>
              </EifsIntro>
            </DContainer>
          </EifsArticle>
        </Eifs>
      </IconContext.Provider>
    </Layout>
  );
});

export default EIFS;
