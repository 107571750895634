import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// styled-component
const CardProductStyled = styled.div`
  max-width: 268px;
  padding: 2em 1em;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:hover {
    background-color: #f9f9f9;
  }
`;

const CardProductTitle = styled.span`
  font-size: 1.1em;
  color: #999999;
  font-weight: 700;

  &:hover {
    color: #000;
  }
`;

const CardProductImage = styled.div`
  margin-bottom: 2.5em;
  text-align: center;
  line-height: 2.5em;
`;

const CardProductButton = styled.span`
  width: 60%;
  min-height: 1.2em;
  padding: 8px 10px;
  font-size: 0.9em;
  font-weight: 700;
  color: #fff;
  background-color: #999999;
  text-transform: uppercase;
  text-align: center;

  &:hover {
    background-color: #ffd700;
    color: #000000;
  }
`;

const CardProduct = React.memo((props) => {
  const { text, link, background } = props;
  const data = useStaticQuery(graphql`
    query MyQuery {
      images: allFile {
        edges {
          node {
            relativePath
            name
            childImageSharp {
              gatsbyImageData(height: 222, layout: FIXED)
            }
          }
        }
      }
    }
  `);
  const image = data.images.edges.find((file) => file.node.relativePath.includes(background));

  return (
    <Link to={link}>
      <CardProductStyled className="card-product">
        <CardProductImage className="card-product-image">
          <GatsbyImage image={image.node.childImageSharp.gatsbyImageData} alt={text} />
          <CardProductTitle className="card-product-title">{text}</CardProductTitle>
        </CardProductImage>
        <CardProductButton className="card-product-button">Conoce más</CardProductButton>
      </CardProductStyled>
    </Link>
  );
});

CardProduct.defaultProps = {
  text: ``,
  link: ``,
  background: ``,
};

CardProduct.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string,
  background: PropTypes.string,
};

export default CardProduct;
